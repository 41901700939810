.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.Content {
  background-color: red;
  min-width: 500px;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.arena_current_level {
  background-color: rosybrown;
}

div.arena_level img {
  opacity: 0.5;
}

div.header_detail {
  color: antiquewhite;
}

span.arena_label {
  font-weight: bold;
}

div.lock_list {
  cursor: pointer;
}

div[status="locked"] {
  background-color: gray;
}

span[status="locked"] {
  background-color: gray;
}

p.warning {
  background-color: mediumpurple;
}

.error_message {
  background-color:#bbb ;
  color: #a00;
}

img.mastery_icon {
  width: 48px;
}

/*
img.marker_icon {
  height: 32px;
}
*/

img.artifact_icon {
  height: 70px;
  width: 70px;
  border-width: thick;
  border-style: solid;
  background-color: #444;
  object-fit: contain;
}

img[rarity="Common"] {
  border-color: #AEAEAE;
}

img[rarity="Uncommon"] {
  border-color: #19D041;
}

img[rarity="Rare"] {
  border-color: #2697FF;
}

img[rarity="Epic"] {
  border-color: #E74AFF;
}

img[rarity="Legendary"] {
  border-color: #EE8100;
}

span.has_popover {
  color: blue;
}

ul.mastery_list {
  text-align: left;
}

ul.skills_list {
  text-align: left;
}

span.skill_name {
  font-style: italic;
}

ul.substats {
  text-align: left;
}

.bonus_popover {
  background-color: aliceblue;
}

div.help_page img {
  border-style: solid;
  border-width: thin;
  border-color: gray;
}

div.artifact_rune {
  display: inline-block;
}

div.artifact_rune div.primary {
  display: inline;
  font-weight: bold;
}

div.artifact_rune div.container {
  width: 70px;
  height: 70px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

div.great_hall_rune div.container {
  width: 120px;
  height: 120px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

img.great_hall_icon {
  height: 120px;
  width: 120px;
  border-width: thin;
  border-style: none;
  object-fit: contain;
}

/* the container can have varying width, so only align 
 * to the left, not the right
 */
div.container div.hall_level_overlay {
  z-index: 2;
  top: 1px;
  opacity: 1.0;
  height:20px;
  color: white;
  background-image: none;
  font-size:18px;
  text-align: center;
  cursor:default;
}

div.container div.hall_bonus_overlay {
  z-index: 3;
  top: 60%;
  opacity: 1.0;
  height:20px;
  color: white;
  background-image: none;
  font-size:18px;
  text-align: center;
  cursor:default;
}

.floats_above {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1.0;
}

div.stars_overlay {
  z-index: 3;
  top: 1px;
  opacity: 1.0;
  height:20px;
  text-align: left;
  color: gold;
  background-image: none;
  font-size:18px;
  left: 8px;
  cursor:default;
}


img.artifact_star {
  height:16px;
  vertical-align: baseline;
}

div.level_overlay {
  z-index: 4;
  top: 40px;
  opacity: 1.0;
  height:24px;
  text-align: right;
  color: white;
  background-image: none;
  font-size:20px;
  left:-6px;
  cursor:default;
}

img.meatball {
  z-index: 8;
  opacity: 1.0;
  height:20px;
  width:20px;
  top:44px;
  left: 5px;
}

picture.champion_avatar {
  margin-right: 1em;
  border-width: medium;
  border-style: solid;
  background-color: #444;
  object-fit: contain;
}

img.champion_avatar {
  margin-right: 1em;
  border-width: medium;
  border-style: solid;
  background-color: #444;
  object-fit: contain;
}

img.champion_avatar_small {
  height: 89px;
  width: 70px;
  left:17px;
  top:15px;
  object-fit: fill;
}

div.champion_stars_overlay {
  z-index: 2;
  top: 11px;
  opacity: 1.0;
  height:19px;
  text-align: left;
  color: gold;
  background-image: none;
  font-size:14px;
  left: 19px;
  cursor:default;
  text-shadow: 2px 2px 4px #000000;
}

div.champion_level_overlay {
  z-index: 1;
  top: 86px;
  opacity: 1.0;
  height:24px;
  text-align: left;
  color: white;
  background-image: none;
  font-size:14px;
  left:70px;
  cursor:default;
  text-shadow: 2px 2px 4px #000000;
}

img.champion_affinity_overlay {
  z-index: 1;
  top: 86px;
  opacity: 1.0;
  height:16px;
  width: 16px;
  object-fit: contain;
  left: 17px;
  cursor:default;
}


img.champion_faction_overlay {
  z-index: 1;
  top: 17px;
  opacity: 1.0;
  height:14px;
  width: 14px;
  object-fit: contain;
  left: 72px;
  cursor:default;
  background-color: rgba(34,34,34,0.7);
}

div.container img.marker_icon {
  z-index: 1;
  top: 68px;
  opacity: 1.0;
  height:14px;
  width: 14px;
  object-fit: contain;
  left: 18px;
  cursor:default;
}

img.vault_icon {
  z-index: 1;
  height:24px;
  width:24px;
  object-fit: contain;
  left: 45px;
  top: 65px;
  cursor: default;
}

img.surround {
  z-index: 0;
  height:98px;
  width:78px;
  object-fit: fill;
  left: 13px;
  top: 11px;
  cursor: default;
}

div.bar_specifier div.ant-select-selector {
  width: 80px;
}

div.range_specifier {
  display:inline-block;
  vertical-align: middle;
  width: 90px;
  padding-bottom: 30px;
}

button.is_lower_bound {
  padding-left: 0.4em;
  padding-right: 0.4em;
}

button.is_lower_bound span {
  background-color: white;
}

span[attr="criticalChance"]::after {
content: "%";
}

span[attr="criticalDamage"]::after {
  content: "%";
  }

  #champion_list_div tr {
    height: 100px;
  }

  div.runed_rows tr {
    height: 120px;
  }

span.all_masteries {
  background-color: lightgreen;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
